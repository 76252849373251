import {
  checkMerchantIdExists,
  checkMerchantIsActiveOrNot
} from "../../MerchantRow/helpers/merchant";
import { getMerchantDataWithParamsByIdInternal } from "../../../../services/merchantService";
import { throwError } from "Utils/errorHandler/errorHandler";

export const getInitialData = async (
  voltranApiManager,
  context
) => {
  // Do not remove "preview"
  /* eslint-disable-next-line no-unused-vars */
  const { preview, merchantId, ...rest } = context.query;

  checkMerchantIdExists(merchantId);
  // const searchParamsExist = checkSearchParamExistsExceptAllowed(context.query);

  try {
    // Add this get operation to settleAll after SF Team gives merchant name directly
    const requestArray = [
      // Get merchant data by id
      getMerchantDataWithParamsByIdInternal(merchantId, voltranApiManager, context.cookies, undefined, undefined, undefined, true)
    ];

    const response = await Promise.allSettled(requestArray);

    const merchantDetail = response[0]?.value?.data?.data;

    const data = {
      merchantDetail
    };

    if (data) {
      checkMerchantIsActiveOrNot(data);

      return data;
    }
  } catch (error) {
    throwError(error, context);
  }
};
