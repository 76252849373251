const voltran = require("/home/node/app/node_modules/voltranjs/src/index");

import React from "react";

import ROUTE_PATHS from "../../../../routes/routeConstants";
import { getInitialData } from "../helpers/initial";
import AboutSeller from "../../MerchantTabular/common/AboutSeller";

const MerchantProfile = ({ initialState }) => {
  const { merchantDetail } = initialState.data;

  return (
    <AboutSeller merchantDetail={merchantDetail} />
  );
};

const component = voltran.default.withBaseComponent(MerchantProfile, ROUTE_PATHS.MERCHANT_PROFILE);

component.services = [
  voltran.default.SERVICES.merchantContentApi
];

component.getInitialState = getInitialData;
export default component;
