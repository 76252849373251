import React, { useState, useRef, useEffect } from "react";
import styles from "./Tooltip.scss";
import cx from "classnames";
import Modal from "@cosmos/hmodal";

const Tooltip = ({ content, renderRefComponent, isMobile }) => {
  const [offsetHeight, setOffsetHeight] = useState(108); // min height default
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef();
  useEffect(() => {
    if (popoverRef.current) setOffsetHeight(popoverRef.current.offsetHeight);
  }, [popoverRef.current]);

  const renderPopover = () => (
    <div className={cx(styles.PopoverWrapper)} ref={popoverRef} style={{ top: -(offsetHeight + 8) }}>
      <div className={styles.Wrapper}>
        <div className={styles.Popover}>{content}</div>
      </div>
    </div>
  );

  const renderBottomSheet = () => (
    <Modal
      viewType='SHEET'
      size='SMALL'
      isOpen={isMobile && isOpen}
      closeButtonEnabled={false}
      openingAnimation
      shouldCloseOnOverlayClick
      scrollLock
      onClose={() => {
        setIsOpen(false);
      }}
      headerClassName={styles.mobileHeader}
      contentClassName={styles.contentWrapper}
    >
      <div className={styles.bottomSheetContent}>{content}</div>
    </Modal>
  );
  return (
    <>
      <div
        className={cx(styles.tooltipWrapper, {
          [styles.isMobile]: isMobile
        })}
        onClick={() => {
          isMobile && setIsOpen(true);
        }}
      >
        {renderRefComponent()}
        {renderPopover()}
      </div>
      {renderBottomSheet()}
    </>
  );
};

export default Tooltip;
